import React,{useState} from "react"
import { Container,Modal } from "react-bootstrap"
import "./OfficeListing.scss"
import DefaultForm from "../forms/default-form-module"
import FormFields from "../../../static/forms/contact_form.json"
import FormFields1 from "../../../static/forms/leave_feedback.json"
import { capitalFirst } from "../Common/utils"

const OfficeListing = ({ officeData, strapi_id,queryValue }) => {
  var imagename = "office.tile_image.list_image"
  const queryTitle = queryValue ? capitalFirst(queryValue?.replace(/-/g, " ")) : ""


  const [show, setShow] = useState(queryValue ? true : false)
  const [title, setTitle]=useState(queryValue ? queryTitle?.replace("And","&") : "")

  const handleModal = data => {
    setShow(true)
    setTitle(data.node?.title)
  }

  const handleClose = () => {
    setShow(false)
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""


  return (
    <div className="office-listing-wrapper" id="our-offices">
      <Container>
        <h4>Our Offices</h4>
        <div className="office-listing-section">
          {officeData?.map((data, index) => {
            let processedImages
            processedImages =
              data?.node?.imagetransforms?.image_Transforms ||
              JSON.stringify({})
            return (
              <div className="office-list" key={data.node?.title}>
                <div className="image-section">
                  {/* <ImageModule
                    ImageSrc={data.node?.tile_image}
                    altText={`${data.node?.title}`}
                    imagetransforms={processedImages}
                    renderer="pic-src"
                    imagename={imagename}
                    strapi_id={strapi_id}
                    classNames="img-fluid"
                  /> */}
                  <img classNames="img-fluid" src={data.node?.tile_image?.url} alt={data.node?.title}/>
                </div>
                <div className="content-section">
                  <h5>{data.node?.title}</h5>
                  <p className="d-flex">
                    <a
                      href={`tel:${data.node?.phone}`}
                      aria-label="phone"
                      className="phone"
                    >
                      <i className="icon icon-call"></i> {data.node?.phone}
                    </a>
                    <span className="slash">/</span>
                    <a onClick={()=>handleModal(data)} href="javascript:void(0)"
                      className="email"
                    >
                      <i className="icon icon-email"></i> <span>Email</span>
                    </a>
                  </p>
                  {data?.node?.add_location?.custom_link&&
                  <div className="navigation-data">
                    <i className="icon map-icon"/>
                    <a target="_blank" href={data?.node?.add_location?.custom_link}>{data.node?.add_location?.title}</a>
                  </div>
                  }
                </div>
              </div>
            )
          })}
        </div>
      </Container>
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form contact-form"
      >
        <Modal.Body>
          <div className="popup-form-wrapper contact-form">
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon black-cancel-icon"></i>
            </div>
            <div>
              <DefaultForm 
                fields={title==="Leave Feedback"?FormFields1:FormFields} 
                formTitle={`${title==="Leave Feedback"?title:`Contact ${title}`}`}
                sourceUrl={pageurl}
                customFormName={title==="Leave Feedback"?"Leave feedback":"Contact - office"}
                office_name={title}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default OfficeListing
